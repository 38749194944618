import React, { useState, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Private from './Private/private';
import Public from './Public/public';
import { getStaticValueSP, getUserProfile } from '../Reducer/authreducer';

export const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [authrize, setAuthrize] = useState(undefined);
  const authUser = useSelector((state) => state.auth.userProfile);

  useEffect(() => {
    if (authUser && Object.values(authUser).length && authUser.id) {
      setAuthrize(true);
      dispatch(getStaticValueSP());
    } else if (localStorage.getItem('_token') && localStorage.getItem('_token') !== '') {
      dispatch(getUserProfile(localStorage.getItem('_userId')));
      // dispatch(getStaticValueSP());
    } else {
      setAuthrize(false);
    }
  }, [authUser])

  return (
      <React.Suspense fallback="Loading...">
        {
          authrize !== undefined ?
            authrize ?
              <Private authUser={authUser} />
              :
              <Public />
            :
            null
        }
      </React.Suspense>    
  )
}
export default withRouter(Index)