import { createSlice } from "@reduxjs/toolkit";
import { getStaticValuebyName } from "../helper";
import { Api } from "../services/httpService";

const commonSlice = createSlice({
  name: "commonSlice",
  initialState: {
    notifications: [],
    userDocuments: [],
    cmsPage: null
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload
    },
    setuserDocuments: (state, action) => {
      state.userDocuments = action.payload
    },
    setCmsPage: (state, action) => {
      state.cmsPage = action.payload
    }
  }
})
export const { notifications, setNotifications, userDocuments, setuserDocuments, cmsPage, setCmsPage } = commonSlice.actions;
export default commonSlice.reducer;

export const getAllNotifications = investerId => async dispatch => {
  try {
    const res = await Api('get', `/notifications/${investerId}`)
    if (res) {
      await dispatch(setNotifications(res))
      return true;
    }
    return false;
  } catch (err) {
    throw err;
  }

}

export const UpdateNotifcations = params => async dispatch => {
  try {
    const res = await Api('post', `/notifications/entry/`, params)
    if (res) {
      await dispatch(setNotifications(res))
      return true;
    }
    return false;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}

export const getDocuments = id => async dispatch => {
  try {
    const res = await Api('post', `/pfpclient/docView`, {
      investorId: id
    });
    if (res) {
      await dispatch(setuserDocuments(res))
      return true;
    }
    return false;
  } catch (err) {
    throw err;
  }
}

export const getCmsPage = (staticValue, propsStaticValues) => async (dispatch) => {
  const staticValueName = getStaticValuebyName(staticValue, propsStaticValues);
  let param = {
    name: staticValueName
  }
  const res = await Api("post", `/staticValues/findAsParam`, param);

  // const res = await Api("get", `/staticValues/${id}`);
  if (res.success) {
    await dispatch(setCmsPage(res.body.content.length > 0 ? res.body.content[0] : {}));
    return true;
  }
  return false;
};



