import { createSlice } from "@reduxjs/toolkit";
import { Api } from "../services/httpService";

const transactionsReducer = createSlice({
  name: "transactionsReducer",
  initialState: {
    creditTransactions: [],
    debitTransactions: [],
    commitments: []
  },
  reducers: {
    setCreditTransactions: (state, action) => {
      state.creditTransactions = action.payload
    },
    setDebitTransactions: (state, action) => {
      state.debitTransactions = action.payload
    },
    setCommitments: (state, action) => {
      state.commitments = action.payload
    }
  }
})
export const { creditTransactions, setCreditTransactions, debitTransactions, setDebitTransactions, setCommitments } = transactionsReducer.actions;
export default transactionsReducer.reducer;

export const getCreditTransactions = (body) => async (dispatch) => {
  try {
    const res = await Api('post', `/pfpclient/ledger/findAsParam`, body);
    if (res.success === true) {
      await dispatch(setCreditTransactions(res.body))
      return true;
    }
    return false;
  } catch (e) {
    console.log("Err", e);
    return false;
  }
}

export const getDebitTransactions = (body) => async (dispatch) => {
  try {
    const res = await Api('post', `/pfpclient/ledger/findAsParam`, body);
    if (res.success === true) {
      await dispatch(setDebitTransactions(res.body))
      return true;
    }
    return false;
  } catch (e) {
    console.log("Err", e);
    return false;
  }
}

export const getCommitments = (body) => async (dispatch) => {
  try {
    const res = await Api('post', `/pfpclient/commitment/findAsParam`, body);
    if (res.success === true) {
      await dispatch(setCommitments(res.body))
      return true;
    }
    return false;
  } catch (e) {
    console.log("Err", e);
    return false;
  }
}