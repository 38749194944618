import Axios from "axios";
import { url } from "../constants";
export const Api = async (reqType, apiRouteUrl, body, headers = {}) => {
    try {
        let finalHeader = Object.assign({}, headers, { "Authorization": localStorage.getItem("_token") ?? "" })
        const requestOptions = {
            url: `${url}${apiRouteUrl}`,
            method: reqType,
            headers: finalHeader,
        };
        if (reqType ==="GET" || reqType === "get" || reqType === "Get") {
            requestOptions['params']=body;
        } else {
            requestOptions['data']=body;
        }
        let response = await Axios(requestOptions);
        return response.data;
    } catch (err) {
        if (err.response) {
            throw err.response
        }
        throw { message: err.message }
    }
}

export const fetchFileFromUrl = async (fileUrl) => {
    try {
        let res = await Axios({
            method:"get",
            url:`${url}/common/docView?filePath=${fileUrl}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("_token") ?? ""
            },
        })
        return res.data;
    } catch (error) {
        if (error.response) {
            throw error.response.data.message
        }else{
            throw error.message
        }
        
    }
}