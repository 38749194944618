import React, { Component } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import 'react-bootstrap-accordion/dist/index.css'

import "typeface-roboto";
import "./App.css";
import Routes from "./Routes";
import { BrowserRouter } from "react-router-dom";

class App extends Component {

  render() {
    return (
      <div className="App_new">
        <div id="sidebarWrap" >
          <div className="App">
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          </div>
        </div>
      </div>
    )
  }
}

export default App;
