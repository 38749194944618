import { createSlice } from "@reduxjs/toolkit";
import { Api } from "../services/httpService";

const analyticsReducer = createSlice({
  name: "analyticsReducer",
  initialState: {
    analyticsData: [],
  },
  reducers: {
    setAnalyticsData: (state, action) => {
      state.analyticsData = action.payload
    }
  }
})
export const { analyticsData, setAnalyticsData } = analyticsReducer.actions;
export default analyticsReducer.reducer;

export const getAnalytics = () => async (dispatch) => {
  try {
    
    const res = await Api('get', `/common/getGenericSPData/InvestorAnalytics?investor_id=${localStorage.getItem('_id')}`);
    if (res.success === true) {
      await dispatch(setAnalyticsData(res.body))
      return true;
    }
    return false;
  } catch (e) {
    console.log("Err", e);
    return false;
  }
}






