import { createSlice } from "@reduxjs/toolkit";
import { Api } from "../services/httpService";

const documentReducer = createSlice({
  name: "documentReducer",
  initialState: {
    allDocuments: {},
  },
  reducers: {
    setAllDocuments: (state, action) => {
      state.allDocuments = action.payload
    }
  }
})
export const { allDocuments, setAllDocuments } = documentReducer.actions;
export default documentReducer.reducer;

export const getDocumentLibrary = (param) => async (dispatch) => {
  var genericParam = {
        'status': true,
        "relationType":"Generic",
        "page":0,
        "limit":200,
        "sort":"validityFrom Desc"
      }

  try {
    // const res = await Api("GET", `/pfpclient/pfpClientDocument/listAsInvestorAndStatus`, param);
    let res = {}
    if(param.investorId){
      res = await Api("POST", `/pfpclient/pfpClientDocument/findAsParam`, param);
    }
    
    let contentArr = [];
    if (res.success) {
        if(res.body?.content.length > 0){
          dispatch(setAllDocuments(res.body?.content))
        }else{
          res = await Api("POST", `/pfpclient/pfpClientDocument/findAsParam`, genericParam);
          if (res.success) {
              dispatch(setAllDocuments(res.body?.content))
          }
        }
      return true
    }
    return res.message;
  } catch (err) {
    console.log("Documents err", err);
    return err;
  }
}