import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Route } from "react-router-dom";

const Public = () => {

  const UserCommonBackground = lazy(() => import("../../components/UserCommonBackground/UserCommonBackground"));

  return (
    <Switch>
      <Route exact path="/" render={props => <UserCommonBackground {...props} />} />
      <Redirect from='*' to="/" />
    </Switch>);
};

export default Public;
