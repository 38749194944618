import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import {
  Route,
} from 'react-router-dom';

const Private = ({ authUser }) => {

  const Dashboard = lazy(() => import("../../components/Dashboard/dashboard"));
  const Analytics = lazy(() => import("../../pages/Analytics/Analytics"));
  const BankStatements = lazy(() => import("../../pages/BankStatements/BankStatements"));
  const Disclosures = lazy(() => import("../../pages/Disclosures/Disclosures"));
  const Documents = lazy(() => import("../../pages/Documents/Documents"));
  const HelpCenter = lazy(() => import("../../pages/HelpCenter/HelpCenter"));
  const Transactions = lazy(() => import("../../pages/Transactions/Transactions"));
  const UserProfile = lazy(() => import("../../pages/UserProfile/UserProfile"));
  

  return (
    
    <Switch>
      <Route exact path="/" render={props => <Dashboard {...props} />} />
      <Route exact path="/userProfile" render={props => <UserProfile  {...props} />} />
      <Route exact path="/analytics" render={props => <Analytics  {...props} />} />
      <Route exact path="/documents" render={props => <Documents  {...props} />} />
      <Route exact path="/transactions" render={props => <Transactions  {...props} />} />
      
      <Route exact path="/bankStatements" render={props => <BankStatements  {...props} />} />
      <Route exact path="/disclosures" render={props => <Disclosures  {...props} />} />
      <Route exact path="/helpCenter" render={props => <HelpCenter  {...props} />} />
      
    </Switch>);
};

export default Private;
