import { createSlice } from "@reduxjs/toolkit";
import { Api } from "../services/httpService";
import { act } from "react";

const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    userProfile: {},
    isAuthenticated: false,
    staticValues: [],
    verifyEmailData:{},
    verifyOtpData:{},
    changePswdData:{}
  },
  reducers: {
    setUser: (state, action) => {
      state.userProfile = action.payload
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload
    },
    setStaticValues: (state, action) => {
      state.staticValues = action.payload
    },
    setEmailVerify :(state,action) =>{
      state.verifyEmailData = action.payload
    },
    setVerifyOtp : (state,action) =>{
      state.verifyOtpData = action.payload
    },
    setChangePassword : (state,action) => {
      state.changePswdData = action.payload
    }
  }
})
export const { setUser, setIsAuthenticated, setStaticValues,setEmailVerify,setVerifyOtp,setChangePassword } = authSlice.actions;
export default authSlice.reducer;

export const setUserResponse = data => async dispatch => {
  await dispatch(setUser(data))
  await dispatch(setIsAuthenticated(true))
  return true;
}

export const getUserProfile = (id) => async (dispatch) => {
  try {
    const res = await Api('get', `/pfpclient/investorId/${id}`);
    if (res.success === true) {
      await dispatch(setUser(res.body))
      return res.body;
    }
    return false;
  } catch (e) {
    console.log("Err", e);
    return false;
  }
}

export const getStaticValueSP = () => async (dispatch) => {
  try {
    const res = await Api('get', `/common/getGenericSPData/Static_value_mapping`);
    if (res.success === true) {
      await dispatch(setStaticValues(res.body))
      return res.body;
    }
    return false;
  } catch (e) {
    console.log("Err", e);
    return false;
  }
}

export const setLogout = () => async (dispatch) => {
  localStorage.setItem("_id", "")
  localStorage.setItem("_token", "")
  localStorage.setItem("_name", "")
  await dispatch(setUser({}))
  await dispatch(setIsAuthenticated(false))
  return true;
}



export const verifyEmail = (params) => async (dispatch) => {
  try {
    const res = await Api('post', `/users/verifyEmail`, params, true)
    if (res) {
      await dispatch(setEmailVerify(res))
      return res;
    }
  } catch (e) {
    return false;
  }
}


export const verifyOtp =(params) => async (dispatch) =>{
  try{
    const res = await Api('post', `/users/verifyOTP`, params, true)
    if (res) {
      await dispatch(setVerifyOtp(res))
      return res;
    }
  }
  catch(e){
    return false
  }
}


export const changPassword =(params) => async (dispatch) =>{
  try{
    const res = await Api('post', `/users/changePswd`, params, true)
    if (res) {
        await dispatch(setChangePassword(res))
    }
  }
  catch(e){
    return false
  }
}