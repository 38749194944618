import { configureStore } from "@reduxjs/toolkit"
import analyticsReducer from "./Reducer/analyticsReducer";
import authreducer from "./Reducer/authreducer";
import BankStatementReducer from "./Reducer/BankStatementReducer";
import commonReducer from "./Reducer/commonReducer";
import DocumentReducer from "./Reducer/DocumentReducer";
import transactionsReducer from "./Reducer/transactionsReducer";
import investorListReducer from "./Reducer/investorListReducer";

const store = configureStore({
  reducer:{
    auth:authreducer,
    common:commonReducer,
    analytics:analyticsReducer,
    transactions:transactionsReducer,
    documents:DocumentReducer,
    statements:BankStatementReducer,
    investorListReducer:investorListReducer
  }
})
export default store