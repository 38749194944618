import t from "../actions/InvestorActions/types";
import moment from 'moment'
const initialState = {
  verifyEmailData: {},
  verifyOtpData: {},
  changePswdData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.VERIFY_EMAIL:
      return {
        ...state,
        verifyEmailData: action.payload ? action.payload : {}
      }

    case t.VERIFY_OTP:
      return {
        ...state,
        verifyOtpData: action.payload ? action.payload : {}
      }
    case t.CHANGE_PSWD:
      return {
        ...state,
        changePswdData: action.payload ? action.payload : {}
      }
    default:
      return state;
  }
};