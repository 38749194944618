import { createSlice } from "@reduxjs/toolkit";
import { Api } from "../services/httpService";

const bankStatementReducer = createSlice({
  name: "bankStatementReducer",
  initialState: {
    allStatements: [],
  },
  reducers: {
    setAllStatements: (state, action) => {
      state.allStatements = action.payload
    }
  }
})
export const { allStatements, setAllStatements } = bankStatementReducer.actions;
export default bankStatementReducer.reducer;

export const getBankStatements = (param) => async (dispatch) => {
  try {
    const res = await Api("POST", `/common/accountDocument/findAsParam`, param);
    if (res.success) {
      dispatch(setAllStatements(res.body))
      return true
    }
    return res.message;
  } catch (err) {
    console.log("Documents err", err);
    return err;
  }
}